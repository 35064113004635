
<!-- component not ready yet -->

<script setup>
    const props = defineProps({
        title: { type: String, required: true  },
        icon : { type: String, required: false },
        createNew: { type: Boolean, required: false },
    });
</script>

<template>
    <div>
        <div class="d-flex gap-2">
            <template v-if="icon">
                <div class="flex-shrink-0">
                    <i class="fa-fw font-1" :class="icon" />
                </div>
            </template>
            <div class="flex-grow-1">
                <div class="font-1 fw-medium">
                    {{ title }}
                </div>
            </div>
            <template v-if="createNew">
                <div class="flex-shrink-0">
                </div>
            </template>
            <template v-if="$slots.createNew">
                <div class="flex-shrink-0">
                    <slot name="createNew" />
                </div>
            </template>
        </div>
        <div>
            <slot />
        </div>
    </div>
</template>
